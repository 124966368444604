$primary-color: #3f4254;
$secondary-color: #7e8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #f1416c;
$red-2-color: #c71624;
$red-3-color: #a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;

.AppAssets {
}
.AppAssets .AppAssetsHeader {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
.AppAssets .uploadAssetsContainer {
  display: flex;
  // gap: 0.5rem;
  flex-direction: column;
  padding: 0 1rem;
}
.AppAssets .uploadAssetsContainer .assestNameLabel{
  // font-size: 1.25rem;
  padding-left: .25rem;
  font-weight: 500;
}
.AppAssets .uploadAssetsContainer .uploadAssetsButtonContainer{
  margin-top: .5rem;
}
.AppAssets .appAssetsListContainer {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: $white-color;
  padding: 1rem;
  border-radius: 6px;
  .appAssetsListTitle {
    font-size: 1.25rem;
    padding-left: 1rem;
    font-weight: 500;
  }
}

.AppAssets .appAssetsList {
  display: flex;
  gap: 0.5rem;
  // flex-direction: column;
  flex-wrap: wrap;
}
.AppAssets .appAssetsList .appAssetsListElem {
  // height: 13rem;
  width: 12rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid $gray-light-3-color;
  transition: 300ms ease-in-out;
  border-radius: 6px;
  padding: 0.5rem;
  // padding-left: 1.5rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &:hover {
    // background-color: $blue-light-2-color;
  }
}
.AppAssets .appAssetsList .appAssetsListElem .appAssetsLoader{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: $black-hover-color;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.AppAssets .appAssetsList .appAssetsListElem .appAssetsListElemHeader {
  display: flex;
  justify-content: flex-end;
}

.AppAssets .appAssetsList .appAssetsListElem .appAssetsListCross {
  height: 2rem;
  width: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 6px;
  font-size: 1.3rem;
  transition: 100ms ease-in-out;
  color: $blue-color;
  &:hover {
    background-color: $blue-light-2-color;
  }
}
.AppAssets .appAssetsList .appAssetsListElem .appAssetsListOption {
  height: 2rem;
  width: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 6px;
  transition: 100ms ease-in-out;
  &:hover {
    background-color: $blue-light-2-color;
  }
}

.AppAssets .appAssetsList .appAssetsListElem .appAssetsImage {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  color: $blue-light-1-color;
  padding: 1rem 0;
  padding-top: 0;
  // margin-top: 1rem;
  img{
    width: 100%;
    height: 8rem;
    object-fit: contain;
    // object-fit: cover;
    border-radius: 6px;
  }
}
.AppAssets .appAssetsList .appAssetsListElem .appAssetsListName {
  font-size: 1.2rem;
  font-weight: 500;
  color: $blue-color;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
