$primary-color: #3f4254;
$secondary-color: #7e8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #f1416c;
$red-2-color: #c71624;
$red-3-color: #a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;


.realtionDataContainer{
  display: flex;
  gap: 1rem;
  height: 40vh;
  // width: 40vw;
  // min-width: 65rem;   
   padding-left: 1rem;
}
.realtionDataContainer .realtionDataContainerLeft{
  // display: flex;
  // gap: 1rem;
  width: 100%;
}
.realtionDataContainer .realtionDataContainerLeft .actionSelectionContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.realtionDataContainer .realtionDataContainerLeft .actionSelectionContainer .actionSelectionContainerTitle{
  font-size: 1.25rem;
  font-weight: 500;
  padding-left: 1rem;
}
.realtionDataContainer .realtionDataContainerLeft .actionSelectionContainer .actionSelectionContainerSubTitle{
  font-weight: 500;
}
.realtionDataContainer .realtionDataContainerLeft .actionSelectionContainer .actionSelectionInnerContainer{
  display: flex;
  flex-direction: column;
  gap: .25rem;
  height: calc(100% - 6.5rem);
  overflow: auto;
}
.realtionDataContainer .realtionDataContainerLeft .actionSelectionContainer .actionSelectionInnerElem{
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: .5rem 1rem;
  border-radius: 6px;
  border: 1px solid $blue-light-2-color;
  cursor: pointer;
  &:hover{
    background-color: $gray-light-3-color;
  }
}
.realtionDataContainer .realtionDataContainerLeft .dataTableContainer{
  display: grid;
  // height: calc(100% - 0rem);
  max-height: 100% ;
  overflow: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 9rem;
  border-radius: 6px;
  border: 1px solid $blue-light-2-color;
  position: relative;
}

.realtionDataContainer .realtionDataContainerLeft .dataTableContainer .dataTableContainerElem{
  padding: .5rem 1rem;
  border: 1px solid $blue-light-2-color;
  &.dataTableContainerElemHeader{
    font-weight: 500;
    background-color: $gray-light-3-color;
    position: sticky;
    top: 0px;
    z-index: 1;
  }
}
.realtionDataContainer .realtionDataContainerLeft .dataTableContainer .dataTableContainerElem .buttonCopied{
  background-color: $green-3-color;
  color: $white-color;

}