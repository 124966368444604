$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;

.MultipleTableUpload{
  
}
.MultipleTableUpload .tableSelectionContainer{
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  margin-bottom: 1rem;
  gap: .5rem;
  border-radius: 6px;
  padding: 1rem;
  border:solid 1px $gray-light-1-color;
  &.tableDisabled{
    background-color: $gray-light-2-color;
  }
}
.MultipleTableUpload .tableSelectionContainer .tableNameContainer{
  display: flex;
  align-items: center;
  gap: 1rem;
} 

.MultipleTableUpload .multiTableContainer{
  display: flex;
  // flex-direction: column;
  height: 100%;
  // gap: .5rem;
  border: solid 1px $blue-light-1-color;
  border-radius: 6px;
}
.MultipleTableUpload .multiTableContainer .tableTabHeader{
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  // margin-left: .5rem;
  width: 20rem;
}
.MultipleTableUpload .multiTableContainer .tableTabHeader .tableTabElem{
  color: $primary-color;
  background-color: $white-color;
  padding: .5rem 1rem;
  margin-bottom: -2px;
  background: none;
  border: 1px solid transparent;
  cursor: pointer;
  // z-index: 1;
  display: flex;
  gap: .5rem;
  align-items: center;
  &:hover{
    background-color: $gray-light-3-color;
  }
  &.active{
  // background: $white-color;
  color: $blue-color;
  background-color: $blue-light-2-color;
}
}
.MultipleTableUpload .multiTableContainer .tableTabHeader .tableTabElem .tableTabElemCheckBoxArea{
  width: 2rem;
}
.MultipleTableUpload .multiTableContainer .tableTabHeader .tableTabElem .tableTabElemTableNameArea{
  width: calc(100% - 2rem);
}
  .MultipleTableUpload .multiTableContainer .tableTabInnerContainer{
  // display: flex;
  width: calc(100% - 20rem);
  height: 43vh;
  // border-radius: 6px;
  padding: 0 2rem;
  overflow: auto;
  border-left: solid 1px $blue-light-1-color;
}
.MultipleTableUpload .multiTableContainer .tableTabInnerContainer{
  width: 100%;
  overflow: auto;
  padding: 0 2rem;
}