$primary-color: #3F4254;
$secondary-color: #7E8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #DFE2EF;
$gray-light-2-color: #EFF0F7;
$gray-light-3-color: #F5F8FA;
$blue-color: #009ef7;
$blue-light-1-color: #B4C9D8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color:#00000025;
$red-1-color:#f1416c ;
$red-2-color:#c71624;
$red-3-color:#a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;

.WarningPopup {
  // height: 40vh;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.WarningPopup .WarningPopupDiscription{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .25rem;
}
.WarningPopup .WarningPopupDiscription .WarningPopupDiscriptionReadMore{
  color: $blue-color;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}

.WarningPopup .affectedDataDetailContainer{
  height: calc(100% - 3rem - 1rem);
  display: flex;
  gap: 1rem;
  justify-content: stretch;
}
.WarningPopup .affectedDataDetailContainer .affectedDataDetailElem{
  border: 1px solid $gray-light-2-color;
  border-radius: 6px;
  padding: 1rem;
  width: calc(50% - .5rem);
}
.WarningPopup .affectedDataDetailContainer .affectedDataDetailElem .affectedDataDetailTitle{
  font-size: 1.2rem;
  font-weight: 500;
  color: $primary-color;
  margin-bottom: .5rem;
  margin-left: 1rem;
}
.WarningPopup .confirmationButtonDiv{
  height: 3rem;
  display: flex;
  justify-content: center;
}
// .WarningPopup .accordionStyle {
//   box-shadow: none;
//   color: $secondary-color; 
//   background-color: $gray-light-3-color;
//   border-radius: 6px;
// }
// .WarningPopup .accordionDetails {
//   box-shadow: none;
//   background-color: $white-color;
//   border: 1px solid $gray-light-2-color;
//   border-radius: 6px;
// }
.WarningPopup .affectedDataDetailContainer .affectedDataDetailElem{
  .affectedDataDetailElemUPDATE{
    color: $blue-color;
  }
  .affectedDataDetailElemCREATE{
    color: $green-2-color;
  }
  .affectedDataDetailElemDELETE{
    color: $red-2-color;
  }
}
.MarkDownContainer{

  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  // background-color: $primary-color;
  // color: $white-color;
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid $gray-light-2-color;
}