$primary-color: #3f4254;
$secondary-color: #7e8299;
$secondary-color-1: #71758b;
$secondary-color-2: #595d74;
$gray-light-1-color: #dfe2ef;
$gray-light-2-color: #eff0f7;
$gray-light-3-color: #f5f8fa;
$blue-color: #009ef7;
$blue-light-1-color: #b4c9d8;
$blue-light-2-color: #f1faff;
$white-color: white;
$black-hover-color: #00000025;
$red-1-color: #f1416c;
$red-2-color: #c71624;
$red-3-color: #a7111e;
$green-1-color: #8effa8;
$green-2-color: #155724;
$green-3-color: #29be4c;


.moreActionContainer{
  display: flex;
  gap: 1rem;
  height: 69vh;
  width: 55vw;
  min-width: 65rem;   
   padding-left: 1rem;
}
.moreActionContainer .moreActionContainerLeft{
  // display: flex;
  // gap: 1rem;
  width: 20rem;
}
.moreActionContainer .moreActionContainerLeft .actionSelectionContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.moreActionContainer .moreActionContainerLeft .actionSelectionContainer .actionSelectionContainerTitle{
  font-size: 1.25rem;
  font-weight: 500;
  padding-left: 1rem;
}
.moreActionContainer .moreActionContainerLeft .actionSelectionContainer .actionSelectionContainerSubTitle{
  font-weight: 500;
}
.moreActionContainer .moreActionContainerLeft .actionSelectionContainer .actionSelectionInnerContainer{
  display: flex;
  flex-direction: column;
  gap: .25rem;
  height: calc(100% - 6.5rem);
  overflow: auto;
}
.moreActionContainer .moreActionContainerLeft .actionSelectionContainer .actionSelectionInnerElem{
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: .5rem 1rem;
  border-radius: 6px;
  border: 1px solid $blue-light-2-color;
  cursor: pointer;
  &:hover{
    background-color: $gray-light-3-color;
  }
}

.templateDataContainer{
  display: flex;
  gap: 1rem;
  height: 40vh;
  // width: 40vw;
  // min-width: 65rem;   
   padding-left: 1rem;
}
.templateDataContainer .templateDataContainerLeft{
  // display: flex;
  // gap: 1rem;
  width: 100%;
}
.templateDataContainer .templateDataContainerLeft .actionSelectionContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.templateDataContainer .templateDataContainerLeft .actionSelectionContainer .actionSelectionContainerTitle{
  font-size: 1.25rem;
  font-weight: 500;
  padding-left: 1rem;
}
.templateDataContainer .templateDataContainerLeft .actionSelectionContainer .actionSelectionContainerSubTitle{
  font-weight: 500;
}
.templateDataContainer .templateDataContainerLeft .actionSelectionContainer .actionSelectionInnerContainer{
  display: flex;
  flex-direction: column;
  gap: .25rem;
  height: calc(100% - 6.5rem);
  overflow: auto;
}
.templateDataContainer .templateDataContainerLeft .actionSelectionContainer .actionSelectionInnerElem{
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: .5rem 1rem;
  border-radius: 6px;
  border: 1px solid $blue-light-2-color;
  cursor: pointer;
  &:hover{
    background-color: $gray-light-3-color;
  }
}

.templateDataContainer .templateDataContainerLeft .dataTableContainer{
  display: grid;
  // height: calc(100% - 0rem);
  max-height: 100% ;
  overflow: auto;
  grid-template-columns: 1fr 1fr 1fr 9rem;
  border-radius: 6px;
  border: 1px solid $blue-light-2-color;
  position: relative;
}

.templateDataContainer .templateDataContainerLeft .dataTableContainer .dataTableContainerElem{
  padding: .5rem 1rem;
  border: 1px solid $blue-light-2-color;
  &.dataTableContainerElemHeader{
    font-weight: 500;
    background-color: $gray-light-3-color;
    position: sticky;
    top: 0px;
    z-index: 1;
  }
}
.templateDataContainer .templateDataContainerLeft .dataTableContainer .dataTableContainerElem .buttonCopied{
  background-color: $green-3-color;
  color: $white-color;

}

.moreActionContainer .moreActionContainerRight{
  // display: flex;
  // gap: 1rem;
  width: calc(100% - 20rem  - 1rem );
}

.moreActionContainer .moreActionContainerRight .selectedActionsTitle{
  height: 2rem;
  font-size: 1.25rem;
  font-weight: 500;
  padding-left: 1.5rem;
}

.moreActionContainer .moreActionContainerRight .moreActionDraggableOuterContainer{
  // display: flex;
  // gap: 1rem;
  height: calc(100% - 2rem );
}
.moreActionContainer .moreActionContainerRight .moreActionDraggableContainer{
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding: 1rem;
  overflow: auto;
  height: 100%;
}
.moreActionContainer .moreActionContainerRight .moreActionDraggableContainer .moreActionDraggableElem{
  display: flex;
  padding: .5rem 1rem;
  border-radius: 6px;
  background-color: $white-color;
  // background-color: $gray-light-3-color;
  border: 1px solid $gray-light-3-color;
}
.moreActionContainer .moreActionContainerRight .moreActionDraggableContainer .moreActionDraggableElem .dragIconOuter{
  width: 2rem;
}
.tableSchemaContainer{
  display: flex;
  gap: 1rem;
  height: 69vh;
  width: 50vw;
  min-width: 50rem;   
  padding-left: 1rem;
}


.tableSchemaContainer .tableSchemaContainerLeft{
  // display: flex;
  // gap: 1rem;
  width: 20rem;
}
.tableSchemaContainer .tableSchemaContainerLeft .tableSelectionContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.tableSchemaContainer .tableSchemaContainerLeft .tableSelectionContainer .tableSelectionContainerTitle{
  font-size: 1.25rem;
  font-weight: 500;
  padding-left: 1rem;
}
.tableSchemaContainer .tableSchemaContainerLeft .tableSelectionContainer .tableSelectionContainerSubTitle{
  font-weight: 500;
}
.tableSchemaContainer .tableSchemaContainerLeft .tableSelectionContainer .actionSelectionInnerContainer{
  display: flex;
  flex-direction: column;
  gap: .25rem;
  height: calc(100% - 6.5rem);
  overflow: auto;
}
.tableSchemaContainer .tableSchemaContainerLeft .tableSelectionContainer .tableSelectionInnerElem{
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: .5rem 1rem;
  border-radius: 6px;
  border: 1px solid $blue-light-2-color;
  cursor: pointer;
  &:hover{
    background-color: $gray-light-3-color;
  }
  &.selectedTableElem{
    background-color: $blue-color;
    color: $white-color;

  }
}
.tableSchemaContainer .tableSchemaContainerRight{
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: calc(100% - 20rem - 1rem );
  padding-left: 1.5rem;
  padding-right: 1rem;
}

.tableSchemaContainer .tableSchemaContainerRight .columnSchemaTitle{
  height: 2rem;
  font-size: 1.25rem;
  font-weight: 500;
}
.tableSchemaContainer .tableSchemaContainerRight .columnSchemaQuestionSection{
  // height: 5.5rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}
.tableSchemaContainer .tableSchemaContainerRight .columnSchemaQuestionSection .columnSchemaQuestionTitle{
  // height: 2rem;
  // font-size: 1.25rem;
  font-weight: 500;
  // padding-left: 1.5rem;
}

.tableSchemaContainer .tableSchemaContainerRight .columnSchemaOuterContainer{
  height: calc(100% - 2rem - .5rem );
  display: flex;
  flex-direction: column;
  gap: .5rem;
  overflow: auto;
}
.tableSchemaContainer .tableSchemaContainerRight .columnSchemaInnerContainer{
  // height: calc(100% - 2rem - 5.5rem - 1rem );
}
.tableSchemaContainer .tableSchemaContainerRight .columnSchemaContainer{
  display: flex;
  flex-direction: column;
  gap: .5rem;
  overflow: auto;
  height: 100%;
}
.tableSchemaContainer .tableSchemaContainerRight .columnSchemaContainer .columnSchemaElem{
  display: flex;
  padding: .5rem 1rem;
  border-radius: 6px;
  background-color: $white-color;
  // background-color: $gray-light-3-color;
  border: 1px solid $gray-light-3-color;
  gap: .5rem;
  cursor: pointer;
  &:hover{
    background-color: $gray-light-3-color;
  }
}
.tableSchemaContainer .tableSchemaContainerRight .columnSchemaContainer .columnSchemaElem .columnData{
  width: calc(100% - 1rem - .5rem);
}
.tableSchemaContainer .tableSchemaContainerRight .columnSchemaContainer .columnSchemaElem .dragIconOuter{
  width: 1rem;
}